import React, { useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Axios from "axios";

import "../styles/form.scss";

const Form = () => {
	const [formSent, setFormSent] = useState(0);

	const formRef = useRef();

	const handleForm = (e) => {
		e.preventDefault();

		let data = Object.fromEntries(new FormData(formRef.current));

		window != undefined &&
			Axios.post("./../../submit.php", data)
				.then(function (response) {
					console.log(response.data);
					setFormSent(1);
				})
				.catch(function (error) {
					console.log(error);
				});
	};

	return (
		<section className="form">
			<div className="form_container">
				<StaticImage
					src="../images/gel.png"
					alt="gel"
					placeholder="transparent"
					className="product one"
					quality={100}
				/>
				<StaticImage
					src="../images/sprej.png"
					alt="sprej"
					placeholder="transparent"
					className="product two"
					quality={100}
				/>
				<StaticImage
					src="../images/rolon.png"
					alt="stick"
					placeholder="transparent"
					className="product three"
					quality={100}
				/>
				<StaticImage
					src="../images/stick.png"
					alt="rolon"
					placeholder="transparent"
					className="product four"
					quality={100}
				/>

				{
					/*
					<form ref={formRef} onSubmit={handleForm}>
					<h2>PRIJAVI SE</h2>

					<label>Ime i prezime*</label>
					<input type="text" name="name" required/>
					<label>Ulica i kućni broj*</label>
					<input type="text" name="address" required/>
					<label>Poštanski broj*</label>
					<input type="text" name="postal_code" required/>
					<label>Mjesto stanovanja*</label>
					<input type="text" name="city" required/>
					<label>Email adresa</label>
					<input type="text" name="email" required/>
					<label>Telefon*</label>
					<input type="text" name="phone" required/>
					<label>Broj računa*</label>
					<input type="text" name="receipt_number" required/>

					{formSent ? (
						<button className="button" style={{cursor: 'auto'}}>Zahvaljujemo na prijavi!</button>
					) : (
						<button type="sumbit" className="button accent">
							Prijavi se
						</button>
					)}
				</form>
				*/
				}
				<form className="dove_iframe" onSubmit={(e)=>{e.preventDefault()}}>
					
				</form>
			</div>
		</section>
	);
};

export default Form;
